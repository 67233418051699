
import { useRate } from '@/modules/app/app'
import TheSelect from '@/modules/in/components/TheSelect.vue'
import { mul } from 'essential/tools/math'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheExchange',
  components: { TheSelect },
  setup () {
    const { rate, refresh } = useRate()

    refresh()

    const list = [500, 1000, 2000, 5000, 10000, 20000, 50000]

    const input = shallowRef(list[0])

    const out = computed(() => {
      return mul(input.value, rate.value.rate)
    })

    return {
      list,
      input,
      out,
    }
  },
})
