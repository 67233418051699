/**
* @author zjc[beica1@outook.com]
* @date 2021/12/14 18:08
* @description
*   TheCopyButton.vue of WeTrade
*/
<template>
<button v-show="code" id="sky_code_copy" class="button">
  <span class="copy">+</span> <t path="deposit_28"></t>
</button>
<button v-show="!code" class="button" @click="$emit('refresh')">
  <TheRefreshIcon sm class="df-cross-center">
    <t path="deposit_30"></t>
  </TheRefreshIcon>
</button>
</template>

<script lang="ts">
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import TheRefreshIcon from '@/modules/in/components/TheRefreshIcon.vue'
import { defineComponent, onMounted } from 'vue'
import Copy from 'clipboard'

export default defineComponent({
  name: 'TheCopyButton',
  components: { TheRefreshIcon },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    onMounted(() => {
      const copy = new Copy('#sky_code_copy', {
        text () {
          return props.code
        },
      })

      copy.on('success', e => {
        showAlert(translate('deposit_29'))
        e.clearSelection()
      })
    })
  },
})
</script>

<style scoped lang="scss">
.button {
  width: 72px;
  height: 24px;
  background: var(--color-white);
  border-radius: 12px;
  border: none;
  font-size: 12px;
  color: var(--color-text);
}

.copy {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  border: 1px solid var(--color-text);
  border-radius: 2px;
  font-size: 10px;
  line-height: 8px;

  &::before {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    width: 9px;
    height: 9px;
    top: -3px;
    left: -3px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: var(--color-text);
    border-radius: 2px;
    content: '';
  }
}
</style>
