/**
* @author zjc[beica1@outook.com]
* @date 2021/12/14 15:30
* @description
*   Channel.61.vue of WeTrade
*/
<template>
<div>
  <template v-if="useOffline">
    <div class="code px-16 py-12 mt-16">
      <t path="deposit_16" as="p" class="f-md c-title">Payment code</t>
      <div class="df-middle mt-8">
        <p class="flex-1 f-xxl c-text f-bold">{{code || '------------------'}}</p>
        <TheCopyButton :code="code" @refresh="refresh" />
      </div>
    </div>
    <div class="wedge" style="height: 32px;"></div>
    <div class="channel">
      <t paht="deposit_17" as="p" class="f-bold">Deposit instructions</t>
      <div class="pt-8 pb-16">
        <TheSelect :options="localChannels" #="{value}" v-model="channel"
          face-class="channel_selector">
          <span class="f-bold c-primary">{{value}}</span>
        </TheSelect>
      </div>
      <div class="c-title f-md">
        <t :path="`deposit_${channel}`" multiline #="{td}" custom>
          <p v-for="(p, index) in td" :key="index" class="mt-4">{{p}}</p>
        </t>
      </div>
    </div>
    <div class="wedge" style="height: 32px"></div>
    <div class="value">
      <t path="deposit_26" as="p" class="f-bold">Deposit instructions</t>
      <TheExchange class="mt-8 mb-16" />
      <div class="c-title f-md">
        <t path="deposit_27" multiline #="{td}" custom>
          <p v-for="(p, index) in td" :key="index" class="mt-4">{{p}}</p>
        </t>
      </div>
    </div>
  </template>
  <ChannelForCommon v-else :channels="channels" :custom-request="submit" />
</div>
</template>

<script lang="ts">
import ChannelForCommon from '@/modules/in/Channel.common.vue'
import TheCopyButton from '@/modules/in/components/TheCopyButton.vue'
import TheExchange from '@/modules/in/components/TheExchange.vue'
import TheSelect from '@/modules/in/components/TheSelect.vue'
import { readSkyPayCode } from '@/modules/in/in.api'
import { defineComponent, shallowRef, watch } from 'vue'
import { ResponseResult } from 'essential/net/http/makeRequestBy'
import { useRoute, useRouter } from 'vue-router'
import * as R from 'ramda'

export default defineComponent({
  name: 'ChannelFor63',
  components: {
    ChannelForCommon,
    TheSelect,
    TheExchange,
    TheCopyButton,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    const channels = [
      'GCash', 'PayMaya', 'TrueMoney', 'ShopeePay', '7-Eleven', 'RD', 'MLhuillier',
    ]
    const router = useRouter()
    const route = useRoute()
    const from = useRoute().path
    const code = shallowRef('')
    const channel = shallowRef(channels[0])
    const useOffline = shallowRef(
      props.channels?.length === 0 ||
      route.query.c === 'ps',
    )

    const refresh = () => readSkyPayCode()
      .then(resp => {
        code.value = resp.contractnumber
      })
      .catch((e: ResponseResult) => {
        if (R.includes(e.c, ['300040', '300036'])) {
          router.replace({
            name: 'fillInBaseFormFor63',
            query: { redirect: from },
          })
        }
      })

    refresh()

    const submit = (
      params: { payChannel: string }, goon: (data: Record<string, unknown>) => void) => {
      if (params.payChannel === 'PH_SKYPAY') {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            c: 'ps',
          },
        })
          .then(() => {
            useOffline.value = true
            const stop = watch(route, () => {
              if (route.query.c !== 'ps') {
                stop()
                useOffline.value = false
              }
            })
          })
      } else {
        return goon(params)
      }
    }

    return {
      code,
      localChannels: channels,
      channel,
      useOffline,
      refresh,
      submit,
    }
  },
})
</script>

<style scoped lang="scss">
.code {
  background: var(--color-light);
}

::v-deep(.channel_selector) {
  height: 40px;
  background: var(--color-light);
}

.face {
  pointer-events: none;
}
</style>
