/**
* @author zjc[beica1@outook.com]
* @date 2021/12/14 19:16
* @description
*   TheSelect.vue of WeTrade
*/
<template>
  <div class="p-r">
    <select v-model="value" v-bind="$attrs" class="select spread">
      <option v-for="option in options" :key="option" :value="option">{{option}}</option>
    </select>
    <div class="face df-middle pl-16 pr-8" :class="faceClass">
      <p class="flex-1">
        <slot :value="modelValue"></slot>
      </p>
      <Icon name="caret-down" sm />
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSelect',
  components: { Icon },
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    faceClass: String,
    modelValue: String,
  },
})
</script>

<style scoped>
.select {
  width: 100%;
  background: transparent;
  appearance: none;
  border: none;
  outline: none;
  border-radius: 2px;
}

.face {
  border: 1px solid #D5DEE8;
  border-radius: 2px;
  position: relative;
  pointer-events: none;
}
</style>
