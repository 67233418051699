/**
* @author zjc[beica1@outook.com]
* @date 2021/12/14 18:39
* @description
*   TheExchange.vue of WeTrade
*/
<template>
  <div class="df-middle">
    <div class="flex-1">
      <TheSelect :options="list" #="{value}" face-class="ex_input" v-model="input">
        <span class="c-primary f-bold">₱{{value}}</span>
      </TheSelect>
    </div>
    <span class="mx-16">=</span>
    <div class="flex-1">
      <input type="text" readonly class="ex_input fill t-center f-bold" :value="`$${out}`">
    </div>
  </div>
</template>

<script lang="ts">
import { useRate } from '@/modules/app/app'
import TheSelect from '@/modules/in/components/TheSelect.vue'
import { mul } from 'essential/tools/math'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheExchange',
  components: { TheSelect },
  setup () {
    const { rate, refresh } = useRate()

    refresh()

    const list = [500, 1000, 2000, 5000, 10000, 20000, 50000]

    const input = shallowRef(list[0])

    const out = computed(() => {
      return mul(input.value, rate.value.rate)
    })

    return {
      list,
      input,
      out,
    }
  },
})
</script>

<style scoped lang="scss">
::v-deep(.ex_input) {
  height: 40px;
  background: var(--color-light);

  &[readonly] {
    border: none;
  }
}
</style>
