
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSelect',
  components: { Icon },
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    faceClass: String,
    modelValue: String,
  },
})
