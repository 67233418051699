
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import TheRefreshIcon from '@/modules/in/components/TheRefreshIcon.vue'
import { defineComponent, onMounted } from 'vue'
import Copy from 'clipboard'

export default defineComponent({
  name: 'TheCopyButton',
  components: { TheRefreshIcon },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    onMounted(() => {
      const copy = new Copy('#sky_code_copy', {
        text () {
          return props.code
        },
      })

      copy.on('success', e => {
        showAlert(translate('deposit_29'))
        e.clearSelection()
      })
    })
  },
})
