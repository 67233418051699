
import ChannelForCommon from '@/modules/in/Channel.common.vue'
import TheCopyButton from '@/modules/in/components/TheCopyButton.vue'
import TheExchange from '@/modules/in/components/TheExchange.vue'
import TheSelect from '@/modules/in/components/TheSelect.vue'
import { readSkyPayCode } from '@/modules/in/in.api'
import { defineComponent, shallowRef, watch } from 'vue'
import { ResponseResult } from 'essential/net/http/makeRequestBy'
import { useRoute, useRouter } from 'vue-router'
import * as R from 'ramda'

export default defineComponent({
  name: 'ChannelFor63',
  components: {
    ChannelForCommon,
    TheSelect,
    TheExchange,
    TheCopyButton,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    const channels = [
      'GCash', 'PayMaya', 'TrueMoney', 'ShopeePay', '7-Eleven', 'RD', 'MLhuillier',
    ]
    const router = useRouter()
    const route = useRoute()
    const from = useRoute().path
    const code = shallowRef('')
    const channel = shallowRef(channels[0])
    const useOffline = shallowRef(
      props.channels?.length === 0 ||
      route.query.c === 'ps',
    )

    const refresh = () => readSkyPayCode()
      .then(resp => {
        code.value = resp.contractnumber
      })
      .catch((e: ResponseResult) => {
        if (R.includes(e.c, ['300040', '300036'])) {
          router.replace({
            name: 'fillInBaseFormFor63',
            query: { redirect: from },
          })
        }
      })

    refresh()

    const submit = (
      params: { payChannel: string }, goon: (data: Record<string, unknown>) => void) => {
      if (params.payChannel === 'PH_SKYPAY') {
        router.push({
          path: route.path,
          query: {
            ...route.query,
            c: 'ps',
          },
        })
          .then(() => {
            useOffline.value = true
            const stop = watch(route, () => {
              if (route.query.c !== 'ps') {
                stop()
                useOffline.value = false
              }
            })
          })
      } else {
        return goon(params)
      }
    }

    return {
      code,
      localChannels: channels,
      channel,
      useOffline,
      refresh,
      submit,
    }
  },
})
